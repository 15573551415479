.statement {
  cursor: pointer;
  display: flex;
  background-color: #ffffffff !important;
  transition: background-color 0.1s;
}

.statement:hover {
  background: rgb(235, 235, 235) !important;
}

.statement.disabled {
  cursor: default;
  background-color: #ffffffff !important;
}

.statement-light {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  text-align: center;
  line-height: 3.5rem;
  font-weight: 700;
  margin: auto;
  position: relative;
  font-size: 2.5rem;
  background-color: white;
  color: rgba(0, 0, 0, 0.226);
  border: 0.15rem rgba(0, 0, 0, 0.226) solid;
}

.statement-light > p {
  font-size: 2rem;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.statement-light > i {
  transform: scale(0.75) translate(0%, -1%);
}

.statement-light[data-rag="purple"] {
  background-color: rgb(142, 68, 173, 0.7);
  color: white;
  border: 0.15rem rgba(0, 0, 0, 0) solid;
}
.statement-light[data-rag="red"] {
  background-color: rgb(255, 0, 0, 0.6);
  color: white;
  border: 0.15rem rgba(0, 0, 0, 0) solid;
}
.statement-light[data-rag="amber"] {
  background-color: rgb(255, 191, 0, 0.6);
  color: white;
  border: 0.15rem rgba(0, 0, 0, 0) solid;
}
.statement-light[data-rag="grey"] {
  background: #c5c5c5;
  color: #fff;
  border: 0.15rem solid rgb(197, 197, 197);
}
.statement-light[data-rag="darkgrey"] {
  background: #fff;
  color: darkslategray;
  border: 0.15rem darkslategray solid;
}
.statement-light[data-rag="solidwhite"] {
  background: white;
  color: white;
  border: 0.15rem rgba(8, 8, 8, 0.25) solid;
}
.statement-light[data-rag="c3"] {
  color: white;
  border: 0.15rem rgba(0, 0, 0, 0) solid;
}
.statement-light[data-rag="green"] {
  background-color: rgba(0, 128, 0, 0.8);
  color: white;
  border: 0.15rem rgba(0, 0, 0, 0) solid;
}
.statement-light[data-rag="greeninverted"] {
  background-color: white;
  color: rgba(0, 128, 0);
  border: 0.15rem rgba(0, 128, 0, 0.6) solid;
}
.statement-light[data-rag="amberinverted"] {
  background-color: white;
  color: rgb(255, 186, 46);
  border: 0.15rem rgb(255, 186, 46, 0.9) solid;
}
.statement-light[data-rag="redinverted"] {
  background-color: white;
  color: rgb(255, 0, 0);
  border: 0.15rem rgb(255, 0, 0, 0.5) solid;
}
.action-icon-holder {
  color: rgba(0, 0, 0, 0.226);
}
