.txt.color1 {
  color: #444444 !important;
}

.bg.color1 {
  background-color: #444444 !important;
}

.bg.color2 {
  background-color: #ebebeb !important;
  color: #444444 !important;
}

.bg.color3 {
  background-color: #ffffff !important;
  color: #000000 !important;
}

.bg.color2 .header {
  color: #444444 !important;
}

.txt.color5 {
  color: #444444 !important;
}

.bg.color4 {
  background-color: #ededee !important;
}

.ui.big.icon.button {
  font-size: 1.2rem !important;
}

button.ui.color1.basic.compact.button {
  border: 1px #444444 solid !important;
  box-shadow: none;
  color: #444444 !important;
}

button.ui.bg.color2.basic.button {
  box-shadow: none;
  text-transform: none;
}

button.ui.bg.color3.basic.button {
  box-shadow: none;
  text-shadow: none;
}

button.ui.bg.color4.basic.button {
  box-shadow: none;
  font-size: 1rem;
}

button.ui.bg.color5.basic.button {
  box-shadow: none;
  border-radius: .28571429rem;
}

button.ui.color1.basic.compact.button:last-child {
  border-right: 1px #444444 solid !important;
}

button.ui.color1.basic.compact.button {
  border-right: 0 !important;
}

.compact.item:not(.horizontal) {
  padding: 1rem !important;
}

.horizontal.compact.item {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ui.compact.segment {
  padding: 0.75rem;
}

.ui.item.segment > .extra {
  color: rgb(133, 132, 132);
  font-size: 0.9rem;
}

.ui.container {
  max-width: 800px !important;
}

.ui.primary.button,
.ui.primary.buttons .button {
  background-color: #444444;
}

.ui.container > .ui.segments:last-of-type {
  margin-bottom: 1rem;
}

.ui.segments > .horizontal.segments:first-child {
  border-top-left-radius: 0.28571429rem;
  border-top-right-radius: 0.28571429rem;
}

.ui.segments > .horizontal.segments:last-child {
  border-bottom-left-radius: 0.28571429rem;
  border-bottom-right-radius: 0.28571429rem;
}

.ui.red.label.signupValidityLabel {
  background-color: #f1c6c6 !important;
  color: #2d2d2d !important;
}

.ui.green.label.signupValidityLabel {
  background-color: #bbf0c9 !important;
  color: #2d2d2d !important;
}

span.link {
  cursor: pointer;
  color: #444444;
}

.field > .ui.checkbox {
  margin-bottom: 1em;
}

@media only screen and (max-width: 1199px) and (min-width: 992px) {
  .ui.container {
    width: 100%;
  }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .ui.container {
    width: 100%;
  }
}

.ui.checkbox input[disabled] ~ label,
.ui.disabled.checkbox label {
  opacity: 1;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ui.horizontal.segments > .segment {
    -ms-flex: none;
  }

  i.large.icon,
  i.large.icons {
    line-height: 2;
  }
}

@media screen and (-ms-high-contrast: active),
  (-ms-high-contrast: none) and (max-width: 768px) {
  .ui.horizontal.segments > .segment {
    -ms-flex: 1 1 auto;
  }
}

@media (forced-colors: active) {
  .ui.radio.checkbox input:checked ~ .box:after,
  .ui.radio.checkbox input:checked ~ label:after {
    background-color: Highlight;
  }
}

.pusher.dimmed {
  min-height: 100vh;
}

.trend-key {
  margin: auto;
}

.trend-key:first-child {
  margin-left: 0.5rem;
}

.trend-key:last-child {
  margin-right: 0.5rem;
}

.ui.form .disabled.field,
.ui.disabled.input,
.ui.form .field.disabled :disabled {
  opacity: 0.9;
}

.ui.form .field.disabled :disabled {
  background: #c5c5c51c;
}

.ui.floating.message > .close.icon {
  display: none;
}

.pusher {
  margin-bottom: 3em;
}
